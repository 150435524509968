<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base" v-if="is_filter">
      <vx-card title="Filter">
        <div class="vx-row mb-5">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <vs-checkbox v-model="is_customer" @change="filterChange()">Customer</vs-checkbox>
            <vs-checkbox v-model="is_vendor" @change="filterChange()">Vendor</vs-checkbox>
            <vs-checkbox v-model="is_driver" @change="filterChange()">Driver</vs-checkbox>
            <vs-checkbox v-model="is_helper" @change="filterChange()">Helper</vs-checkbox>
            <vs-checkbox v-model="is_sales" @change="filterChange()">Sales</vs-checkbox>
          </div>
        </div>
        <div class="vx-col w-full flex mt-3">
          <vs-button color="warning" @click="filter(false)">Hide</vs-button>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vx-card :title="title">
        <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
          <template slot="header">
            <vs-button color="primary" class='mr-1' :to="{name: 'contacts-create'}" v-if='is_contact'>Add New</vs-button>&nbsp;
            <vs-button color="primary" :to="{name: 'driver_contacts-create'}" v-if='is_driver_contact'>Add New</vs-button>&nbsp;
            <vs-button color="success" class='mr-1' v-if='!is_driver_contact' @click="filter(true)">Filter</vs-button>
          </template>
          <template slot="thead">
            <vs-th sort-key="name">Name</vs-th>
            <vs-th sort-key="address">Address</vs-th>
            <vs-th sort-key="phone">Phone</vs-th>
            <vs-th sort-key="is_active">Contact Type</vs-th>
            <vs-th sort-key="is_active">Status</vs-th>
            <vs-th sort-key="created_at"></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.name">
                <router-link v-if='is_contact' :to="{name: 'contacts-show', params: {id: tr.id}}" v-html='tr.name'></router-link>
                <router-link v-if='is_driver_contact' :to="{name: 'driver_contacts-show', params: {id: tr.id}}">{{tr.name}}</router-link>
              </vs-td>
              <vs-td label='address' :data="tr.address">{{tr.address}}</vs-td>
              <vs-td label='phone' :data="tr.no_telp">{{tr.phone}}</vs-td>

              <vs-td label='type'>
                <span v-if="tr.is_customer">Customer<span v-if="tr.is_vendor || tr.is_driver || tr.is_helper || tr.is_sales">, </span></span>
                <span v-if="tr.is_vendor">Vendor<span v-if="tr.is_driver || tr.is_helper || tr.is_sales">, </span></span>
                <span v-if="tr.is_driver">Driver<span v-if="tr.is_helper || tr.is_sales">, </span></span>
                <span v-if="tr.is_helper">Helper<span v-if="tr.is_sales">, </span></span>
                <span v-if="tr.is_sales">Sales</span>
              </vs-td>

              <vs-td :data="tr.is_active">
                <vs-chip size="small" v-if="tr.is_active" color="success"><vs-avatar color="success" icon-pack="feather" icon="icon-check"></vs-avatar> ACTIVE</vs-chip>
                <vs-chip size="small" v-if="!tr.is_active" color="danger"><vs-avatar color="danger" icon-pack="feather" icon="icon-x"></vs-avatar> NOT ACTIVE</vs-chip>                
              </vs-td>
              
              <vs-td :data="tr.id" width="10">
                <div class="flex">
                  <vs-button size="small" :to="{name:'contacts-edit', params:{id: tr.id}}" type="line" icon-pack="feather" icon="icon-edit"></vs-button>
                  <vs-button v-if="!tr.company_contact_id" size="small" @click="deletes(tr.id)" type="line" color='danger' icon-pack="feather" icon="icon-trash"></vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  props : ['is_driver', 'is_driver_contact', 'is_contact'],
  data(){
    return {
      title:'All Contact',
      idDelete: null,
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null,
      is_filter: false,
      is_customer: false,
      is_vendor: false,
      is_helper: false,
      is_sales: false
    }
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.contacts.rows
    })
  },
  methods:{
    ...mapActions({
      dispatchContactIndex: 'contacts/index',
      dispatchDestroy: 'contacts/destroy'
    }),
    filter(state) {
      this.is_filter = state;
      this.is_customer = false;
      this.is_vendor = false;
      this.is_driver = false;
      this.is_helper = false;
      this.is_sales = false;
      this.getData();
    },
    async filterChange() {
      this.getData();
    },
    async getData(){
      let payload = {};
      if(this.is_customer || this.is_vendor || this.is_driver || this.is_helper || this.is_sales) {
        payload = {
          page: this.currentPage,
          search: this.search,
          sortKey: this.sortKey,
          sortVal: this.sortVal,
          is_customer: this.is_customer,
          is_vendor: this.is_vendor,
          is_driver: this.is_driver,
          is_helper: this.is_helper,
          is_sales: this.is_sales
        }
      }
      else {
        payload = {
          page: this.currentPage,
          search: this.search,
          sortKey: this.sortKey,
          sortVal: this.sortVal
        }
      }
      await this.dispatchContactIndex(payload)
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    },
    async confirmDelete(){
      try {
        await this.dispatchDestroy(this.idDelete)
        this.getData()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    deletes(id){
      this.idDelete = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDelete
      })
    },
    async init() {
        this.is_driver_contact = this.$route.name == 'driver_contacts'
        if(this.is_driver_contact) {
            this.title = 'All Driver'
            this.is_driver = true
            await this.getData()
        } else {
            this.is_contact = true
            await this.getData()
        }
    }
  },
  async created(){
    setTimeout( () => {

        this.init()
    }, 300)
  }
}
</script>

<style>

</style>